var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useContext, useState, useEffect } from 'react';
var Breakpoint = {
    xs: {
        minWidth: 0,
    },
    sm: {
        minWidth: 576,
    },
    md: {
        minWidth: 768,
    },
    lg: {
        minWidth: 992,
    },
    xl: {
        minWidth: 1200,
    },
};
var getMatchMedia = function (width, mode) { return window.matchMedia("(".concat(mode, "-width: ").concat(width, "px)")); };
var useBreakpointInternal = function (breakpointName, mode) {
    if (mode === void 0) { mode = 'min'; }
    var breakpoint = Breakpoint[breakpointName];
    var width = mode === 'min' ? breakpoint.minWidth : breakpoint.minWidth - 1;
    var _a = __read(useState(getMatchMedia(width, mode).matches), 2), isBreakpointActive = _a[0], setIsBreakpointActive = _a[1];
    useEffect(function () {
        var breakpoint = Breakpoint[breakpointName];
        var width = mode === 'min' ? breakpoint.minWidth : breakpoint.minWidth - 1;
        var match = getMatchMedia(width, mode);
        var handleMatchChange = function (e) {
            setIsBreakpointActive(e.matches);
        };
        match.addEventListener('change', handleMatchChange);
        setIsBreakpointActive(match.matches);
        return function () {
            match.removeEventListener('change', handleMatchChange);
        };
    }, [breakpointName, mode]);
    return isBreakpointActive;
};
var BreakpointContext = React.createContext({
    min: { activeBreakpoints: new Set() },
    max: { activeBreakpoints: new Set() },
});
var BreakpointProvider = function (props) {
    var isXsBreakpointActive = useBreakpointInternal('xs');
    var isSmBreakpointActive = useBreakpointInternal('sm');
    var isMdBreakpointActive = useBreakpointInternal('md');
    var isLgBreakpointActive = useBreakpointInternal('lg');
    var isXlBreakpointActive = useBreakpointInternal('xl');
    var isBelowXsBreakpointActive = useBreakpointInternal('xs', 'max');
    var isBelowSmBreakpointActive = useBreakpointInternal('sm', 'max');
    var isBelowMdBreakpointActive = useBreakpointInternal('md', 'max');
    var isBelowLgBreakpointActive = useBreakpointInternal('lg', 'max');
    var isBelowXlBreakpointActive = useBreakpointInternal('xl', 'max');
    var minActiveBreakpoints = new Set();
    var maxActiveBreakpoints = new Set();
    if (isXsBreakpointActive)
        minActiveBreakpoints.add('xs');
    if (isSmBreakpointActive)
        minActiveBreakpoints.add('sm');
    if (isMdBreakpointActive)
        minActiveBreakpoints.add('md');
    if (isLgBreakpointActive)
        minActiveBreakpoints.add('lg');
    if (isXlBreakpointActive)
        minActiveBreakpoints.add('xl');
    if (isBelowXsBreakpointActive)
        maxActiveBreakpoints.add('xs');
    if (isBelowSmBreakpointActive)
        maxActiveBreakpoints.add('sm');
    if (isBelowMdBreakpointActive)
        maxActiveBreakpoints.add('md');
    if (isBelowLgBreakpointActive)
        maxActiveBreakpoints.add('lg');
    if (isBelowXlBreakpointActive)
        maxActiveBreakpoints.add('xl');
    return (_jsx(BreakpointContext.Provider, { value: {
            min: {
                activeBreakpoints: minActiveBreakpoints,
            },
            max: {
                activeBreakpoints: maxActiveBreakpoints,
            },
        }, children: props.children }));
};
var useBreakpoint = function (breakpointName, mode) {
    if (mode === void 0) { mode = 'min'; }
    var result = useContext(BreakpointContext);
    return result[mode].activeBreakpoints.has(breakpointName);
};
export { BreakpointProvider, useBreakpoint };
