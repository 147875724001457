var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { useEffect, useLayoutEffect, useState, useRef, useCallback } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { isOrContains } from './dom';
import copyToClipboard from 'copy-to-clipboard';
import { debounce } from 'lodash';
export var useTimeout = function (callback, ms) {
    var savedCallback = useRef();
    // Remember the latest callback.
    useEffect(function () {
        savedCallback.current = callback;
    }, [callback]);
    // Set up the interval.
    useEffect(function () {
        var tick = function () {
            if (savedCallback.current) {
                savedCallback.current();
            }
        };
        var id;
        if (ms) {
            id = global.setTimeout(tick, ms);
        }
        return function () {
            if (id)
                clearTimeout(id);
        };
    }, [ms]);
};
export var useDocumentTitle = function (title) {
    useLayoutEffect(function () {
        var prevTitle = document.title;
        document.title = title;
        return function () {
            document.title = prevTitle;
        };
    }, [title]);
};
export var useDocumentTitleSuffix = function (titleSuffix) {
    useDocumentTitle(titleSuffix ? "OnRamp - ".concat(titleSuffix) : 'OnRamp');
};
export var useHover = function () {
    var _a = __read(useState(false), 2), value = _a[0], setValue = _a[1];
    var ref = useRef(null);
    var handleMouseOver = function () { return setValue(true); };
    var handleMouseOut = function () { return setValue(false); };
    useEffect(function () {
        if (!ref.current)
            return;
        var node = ref.current;
        var handleMouseOver = function () { return setValue(true); };
        var handleMouseOut = function () { return setValue(false); };
        node.addEventListener('mouseenter', handleMouseOver);
        node.addEventListener('mouseleave', handleMouseOut);
        return function () {
            node.removeEventListener('mouseenter', handleMouseOver);
            node.removeEventListener('mouseleave', handleMouseOut);
        };
    }, [ref.current]);
    var resetHover = function () {
        // for manual override - i.e. if the DOM is modified in such a way that mouseLeave is not triggered
        setValue(false);
    };
    return [ref, value, resetHover];
};
export function useFirstRender() {
    var firstRender = useRef(true);
    useEffect(function () {
        firstRender.current = false;
    }, []);
    return firstRender.current;
}
export var useDynamicDnd = function (_a) {
    var type = _a.type, index = _a.index, onMoveItem = _a.onMoveItem, mode = _a.mode, _b = _a.enabled, enabled = _b === void 0 ? true : _b;
    var previewRef = useRef(null);
    var handleRef = useRef(null);
    var _c = __read(useDrop({
        accept: type,
        canDrop: function () { return enabled; },
        hover: debounce(function (item, monitor) {
            if (!previewRef.current)
                return;
            var dragIndex = item.index;
            var hoverIndex = index;
            // Don't replace items with themselves
            if (dragIndex === hoverIndex)
                return;
            // Determine rectangle on screen
            var hoverBoundingRect = previewRef.current.getBoundingClientRect();
            // Determine mouse position
            var clientOffset = monitor.getClientOffset();
            if (!clientOffset) {
                // When hover handler is called after dropping (due to debounce)
            }
            else if (mode === 'vertical') {
                // Get vertical middle
                var hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                // Get pixels to the top
                var hoverClientY = clientOffset.y - hoverBoundingRect.top;
                // Dragging next
                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY)
                    return;
                // Dragging previous
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY)
                    return;
            }
            else {
                // Get horizontal middle
                var hoverMiddleX = (hoverBoundingRect.left - hoverBoundingRect.right) / 2;
                // Get pixels to the left
                var hoverClientX = clientOffset.x - hoverBoundingRect.left;
                // Dragging next
                if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX)
                    return;
                // Dragging previous
                if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX)
                    return;
            }
            // Time to actually perform the action
            onMoveItem(dragIndex, hoverIndex);
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex;
        }, 300, { trailing: true }),
    }), 2), drop = _c[1];
    var _d = __read(useDrag({
        type: type,
        canDrag: enabled,
        item: { index: index },
        collect: function (monitor) { return ({
            isDragging: monitor.isDragging(),
        }); },
    }), 3), isDragging = _d[0].isDragging, drag = _d[1], preview = _d[2];
    drag(handleRef);
    preview(previewRef);
    drop(previewRef);
    return { isDragging: isDragging, previewRef: previewRef, handleRef: handleRef };
};
export var useOnClickOutside = function (ref, handler, active) {
    useEffect(function () {
        if (!active)
            return;
        var listener = function (event) {
            if (!ref.current || isOrContains(ref.current, event.target))
                return;
            handler();
        };
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);
        return function () {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [ref, handler, active]);
};
export var useIsFirstMount = function () {
    var isFirstMount = useRef(true);
    if (isFirstMount.current) {
        isFirstMount.current = false;
        return true;
    }
    return isFirstMount.current;
};
export var useMergedRef = function () {
    var refs = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        refs[_i] = arguments[_i];
    }
    return useCallback(function (element) {
        for (var i = 0; i < refs.length; i++) {
            var ref = refs[i];
            if (typeof ref === 'function') {
                ref(element);
            }
            else if (ref && typeof ref === 'object') {
                ;
                ref.current = element;
            }
        }
    }, refs); // eslint-disable-line react-hooks/exhaustive-deps
};
export var usePrevious = function (value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    }, [value]);
    return ref.current;
};
export var useClipboard = function () {
    var _a = __read(useState(false), 2), isCopied = _a[0], setIsCopied = _a[1];
    var copy = function (text) {
        copyToClipboard(text);
        setIsCopied(true);
    };
    return { isCopied: isCopied, copy: copy };
};
export var useOutsideAlerter = function (ref, callback) {
    useEffect(function () {
        /**
         * Alert if clicked on outside of element
         */
        var handleClickOutside = function (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        };
        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            // Unbind the event listener on clean up
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
};
