var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import * as styles from './index.css';
var BouncingDots = function (props) {
    var _a;
    return (_jsx("div", __assign({}, props, { className: clsx(styles.bouncingDots, (_a = {}, _a[styles.bouncingDotsNoPadding] = props.noPadding, _a), props.className), children: _jsxs("svg", { className: styles.content, width: "60", height: "20", viewBox: "0 0 60 21", xmlns: "http://www.w3.org/2000/svg", children: [_jsx("circle", { className: styles.item, cx: "7", cy: "15", r: "4" }), _jsx("circle", { className: styles.item, cx: "30", cy: "15", r: "4" }), _jsx("circle", { className: styles.item, cx: "53", cy: "15", r: "4" })] }) })));
};
export { BouncingDots };
