var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { StrictMode, useState, useEffect, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import loadable from '@loadable/component';
import { MatomoProvider, createInstance, useMatomo } from '@jonkoops/matomo-tracker-react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Bowser from 'bowser';
import reportWebVitals from 'reportWebVitals';
import { useTimeout } from 'util/hooks';
import { BouncingDots } from 'components/Loading/BouncingDots';
import './reset.css';
import './index.css';
// This must come after importing ./index.css to ensure it takes precedent
import '@onramp/shared-ui/dist/shared-ui.css';
import { BreakpointProvider } from 'components/BreakpointProvider';
// This prevents content flashing for quickly loaded modules
var ModuleLoading = function () {
    var _a = __read(useState(false), 2), pastDelay = _a[0], setPastDelay = _a[1];
    useTimeout(function () {
        setPastDelay(true);
    }, 500);
    if (pastDelay)
        return _jsx(BouncingDots, {});
    return null;
};
var AsyncTenantRoutes = loadable(function () { return import(/* webpackChunkName: "admin" */ 'pages/admin'); }, { fallback: _jsx(ModuleLoading, {}) });
var AsyncUserRoutes = loadable(function () { return import(/* webpackChunkName: "user" */ 'pages/user'); }, { fallback: _jsx(ModuleLoading, {}) });
var TrackPageView = function () {
    var location = useLocation();
    var trackPageView = useMatomo().trackPageView;
    useEffect(function () {
        trackPageView({
            documentTitle: "".concat(document.domain, " / ").concat(document.title),
        });
    }, [trackPageView, location.pathname, location.hash, location.search]);
    return _jsx(_Fragment, {});
};
var queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            retry: false,
        },
    },
});
var matomoInstance = createInstance({
    urlBase: 'https://onramp.matomo.cloud',
    siteId: 1,
    disabled: !window.location.host.endsWith('onramp.co'),
});
var useDndBackend = function () {
    var backend = useMemo(function () {
        var platformType = Bowser.parse(window.navigator.userAgent).platform.type;
        var shouldUseTouch = platformType === 'mobile' || platformType === 'tablet';
        return shouldUseTouch ? TouchBackend : HTML5Backend;
    }, []);
    return backend;
};
var App = function () {
    var backend = useDndBackend();
    return (_jsx(StrictMode, { children: _jsx(DndProvider, { backend: backend, children: _jsx(BreakpointProvider, { children: _jsx(MatomoProvider, { value: matomoInstance, children: _jsxs(QueryClientProvider, { client: queryClient, children: [_jsxs(Router, { children: [_jsx(QueryParamProvider, { ReactRouterRoute: Route, children: _jsxs(Switch, { children: [_jsx(Route, { path: "/admin", children: _jsx(AsyncTenantRoutes, {}) }), _jsx(Route, { path: "/", children: _jsx(AsyncUserRoutes, {}) })] }) }), _jsx(TrackPageView, {})] }), _jsx(ReactQueryDevtools, {})] }) }) }) }) }));
};
ReactDOM.render(_jsx(App, {}), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
